import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import ContinentSettings from '../../constants/continents/geo-continents';
import companySettings from '../../constants/company/settings';
import {useStaticQuery, graphql} from "gatsby";
import {Trans} from "react-i18next";

const Dealers = (props) => {
  const Dealers = useStaticQuery(graphql`
    query MyQuery {
  allDealersXlsxNorthAmerica {
    edges {
      node {
        id
        company_name
        company_type
        location
        street_po_box
        city
        state
        zip_code
        country
        phone
        fax
        email
        website
      }
    }
  }
  allDealersXlsxMiddleEast {
    edges {
      node {
        id
        company_name
        company_type
        location
        street_po_box
        city
        state
        zip_code
        country
        phone
        fax
        email
        website
      }
    }
  }
  allDealersXlsxLatin {
    edges {
      node {
        id
        company_name
        company_type
        location
        street_po_box
        city
        state
        zip_code
        country
        phone
        fax
        email
        website
      }
    }
  }
  allDealersXlsxEurope {
    edges {
      node {
        id
        company_name
        company_type
        location
        street_po_box
        city
        state
        zip_code
        country
        phone
        fax
        email
        website
      }
    }
  }
  allDealersXlsxAsia {
    edges {
      node {
        id
        company_name
        company_type
        location
        street_po_box
        city
        state
        zip_code
        country
        phone
        fax
        email
        website
      }
    }
  }
}
  `);
  const [selectedLocation, setSelectedLocation] = useState(ContinentSettings.NORTH_AMERICA);
  const [currentLocationPartners, setCurrentLocationPartners] = useState(Dealers.allDealersXlsxNorthAmerica.edges);
  const [northAmericanManufacturers, setNorthAmericanManufacturers] = useState(null);
  const [northAmericanDealers, setNorthAmericanDealers] = useState(null);
  const [northAmericanDistributors, setNorthAmericanDistributors] = useState(null);
  const [naRenderIssue, setNaRenderIssue] = useState(false);

  function handleNorthAmericanManufacturers() {
    const filteredItems = [];
    currentLocationPartners.forEach(({node: partner}) => {
      if (partner.company_type === companySettings.WTB_MANUFACTURER) {
        filteredItems.push(partner);
      }
    });
    setNorthAmericanManufacturers(filteredItems);
  }

  function handleNorthAmericanDealers() {
    const filteredItems = [];
    currentLocationPartners.forEach(({node: partner}) => {
      if (partner.company_type === companySettings.WTB_DEALER) {
        filteredItems.push(partner);
      }
    });
    setNorthAmericanDealers(filteredItems);
  }

  function handleNorthAmericanDistributors() {
    const filteredItems = [];
    currentLocationPartners.forEach(({node: partner}) => {
      if (partner.company_type === companySettings.WTB_DISTRIBUTOR) {
        filteredItems.push(partner);
      }
    });
    setNorthAmericanDistributors(filteredItems);
  }

  function handleDealers(e) {
    switch (e.target.getAttribute('data-dealers')) {
      case ContinentSettings.EUROPE:
        setCurrentLocationPartners(Dealers.allDealersXlsxEurope.edges);
        setSelectedLocation(ContinentSettings.EUROPE);
        setNaRenderIssue(false);
        break;
      case ContinentSettings.AFRICA:
        setCurrentLocationPartners(Dealers.allDealersXlsxMiddleEast.edges);
        setSelectedLocation(ContinentSettings.AFRICA);
        setNaRenderIssue(false);
        break;
      case ContinentSettings.ASIA:
        setCurrentLocationPartners(Dealers.allDealersXlsxAsia.edges);
        setSelectedLocation(ContinentSettings.ASIA);
        setNaRenderIssue(false);
        break;
      case ContinentSettings.SOUTH_AMERICA:
        setCurrentLocationPartners(Dealers.allDealersXlsxLatin.edges);
        setSelectedLocation(ContinentSettings.SOUTH_AMERICA);
        setNaRenderIssue(false);
        break;
      default:
        setCurrentLocationPartners(Dealers.allDealersXlsxNorthAmerica.edges);
        setSelectedLocation(ContinentSettings.NORTH_AMERICA);
        handleNorthAmericanDealers();
        handleNorthAmericanManufacturers();
        handleNorthAmericanDistributors();
        setTimeout(() => {
          if(!naRenderIssue) {
            document.getElementById('buttonNorthAmerica').click();
            setNaRenderIssue(true);
            console.log('render issue');
          }
        }, 800);

    }
  }

  function isEmpty(obj) {
    return obj === null;
  }

  function compare(a, b) {
    if (a.company_name < b.company_name) {
      return -1;
    }
    if (a.company_name > b.company_name) {
      return 1;
    }
    return 0;
  }

  function compareNode(a, b) {
    if (a.node.company_name < b.node.company_name) {
      return -1;
    }
    if (a.node.company_name > b.node.company_name) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (sessionStorage.getItem(ContinentSettings.SESSION_CONTINENT) !== null) {
      let cs = sessionStorage.getItem(ContinentSettings.SESSION_CONTINENT);
      if (cs === ContinentSettings.ASIA) {
        document.getElementById('buttonAsia').click();
      } else if (cs === ContinentSettings.EUROPE) {
        document.getElementById('buttonEurope').click();
      } else if (cs === ContinentSettings.SOUTH_AMERICA) {
        document.getElementById('buttonSouthAmerica').click();
      } else if (cs === ContinentSettings.OCEANIA) {
        document.getElementById('buttonAsia').click();
      } else if (cs === ContinentSettings.ANTARCTICA) {
        document.getElementById('buttonEurope').click();
      } else if (cs === ContinentSettings.AFRICA) {
        document.getElementById('buttonMiddleEast').click();
      } else {
        //North America
        handleNorthAmericanDealers();
        handleNorthAmericanManufacturers();
        handleNorthAmericanDistributors();
        document.getElementById('buttonNorthAmerica').click();
      }

    } else {
      //North America
      handleNorthAmericanDealers();
      handleNorthAmericanManufacturers();
      handleNorthAmericanDistributors();
    }

  }, []);

  return (
      <>
        <section className="find-partner">
          <div className="container">
            <h1>
              <Trans>
                find_partner
              </Trans>
            </h1>
            {/*<GeoLocation />*/}
          </div>
        </section>
        <section className="partner-locations">
          <div className="container">
            <div
                className={selectedLocation === ContinentSettings.NORTH_AMERICA ? 'button selected bottom' : 'button'}
                id={'buttonNorthAmerica'}
                onClick={handleDealers}
                data-dealers={ContinentSettings.NORTH_AMERICA}>
              North America
            </div>
            <div
                className={selectedLocation === ContinentSettings.EUROPE ? 'button selected bottom' : 'button'}
                id={'buttonEurope'}
                onClick={handleDealers}
                data-dealers={ContinentSettings.EUROPE}>
              Europe
            </div>
            <div
                className={selectedLocation === ContinentSettings.AFRICA ? 'button selected bottom' : 'button'}
                id={'buttonMiddleEast'}
                onClick={handleDealers}
                data-dealers={ContinentSettings.AFRICA}>
              Middle East
            </div>
            <div
                className={selectedLocation === ContinentSettings.ASIA ? 'button selected bottom' : 'button'}
                id={'buttonAsia'}
                onClick={handleDealers}
                data-dealers={ContinentSettings.ASIA}>
              Asia
            </div>
            <div
                className={selectedLocation === ContinentSettings.SOUTH_AMERICA ? 'button selected bottom' : 'button'}
                id={'buttonSouthAmerica'}
                onClick={handleDealers}
                data-dealers={ContinentSettings.SOUTH_AMERICA}>
              Latin America
            </div>
          </div>
        </section>

        <section>

          {
              selectedLocation === ContinentSettings.NORTH_AMERICA &&
              <div className="container dealers-bucketed">
                <div className="dealer-locations">
                  <h3>{companySettings.WTB_DEALER + 's'}</h3>
                  {
                      northAmericanDealers && northAmericanDealers.sort(compare).map(dealer => (
                          <div className={'dealer'} key={dealer.id}>
                            <h4>{dealer.company_name}</h4>
                            <p>
                              {
                                isEmpty(dealer.city) ?
                                    null
                                    :
                                    <>
                                      {dealer.city}, {dealer.state}
                                      <br/>
                                    </>
                              }
                              {dealer.country}<br/>
                              <a
                                  href={dealer.website}
                                  target={'_blank'}
                                  rel={'noreferrer'}
                              >
                                Visit Website
                              </a>

                            </p>
                          </div>
                      ))
                  }
                </div>
                <div className="dealer-locations">
                  <h3>{companySettings.WTB_MANUFACTURER + 's'}</h3>
                  {
                      northAmericanManufacturers && northAmericanManufacturers.sort(compare).map(dealer => (
                          <div className={'dealer'} key={dealer.id}>
                            <h4>{dealer.company_name}</h4>
                            <p>

                              {
                                isEmpty(dealer.city) ?
                                    null
                                    :
                                    <>
                                      {dealer.city}, {dealer.state}
                                      <br/>
                                    </>
                              }

                              {dealer.country}<br/>
                              <a href={dealer.website} target={'_blank'} rel={'noreferrer'}>
                                Visit Website
                              </a>

                            </p>
                          </div>
                      ))
                  }
                </div>

                {
                    northAmericanDistributors && northAmericanDistributors.sort(compare).map(dealer => (
                        <div className="dealer-locations">
                          <h3>{companySettings.WTB_DISTRIBUTOR + 's'}</h3>
                          <div className={'dealer'} key={dealer.id}>
                            <h4>{dealer.company_name}</h4>
                            <p>

                              {
                                isEmpty(dealer.city) ?
                                    null
                                    :
                                    <>
                                      {dealer.city}, {dealer.state}
                                      <br/>
                                    </>
                              }
                              {dealer.country}<br/>
                              <a
                                  href={dealer.website}
                                  target={'_blank'}
                                  rel={'noreferrer'}>
                                Visit Website
                              </a>

                            </p>
                          </div>
                        </div>
                    ))
                }

              </div>
          }
          {
              selectedLocation !== ContinentSettings.NORTH_AMERICA &&
              <div className="container dealer-locations">
                {

                  currentLocationPartners.sort(compareNode).map(({node: dealer}, index) => (
                      <div className={'dealer'} key={index}>

                        <h4>{dealer.company_name}</h4>
                        <p>
                          {
                            isEmpty(dealer.city) ?
                                null
                                :
                                <>
                                  {dealer.city}, {dealer.state}
                                  <br/>
                                </>
                          }
                          {dealer.country}<br/>
                          <a href={dealer.website}
                             target={'_blank'}
                             rel={'noreferrer'}>
                            Visit Website
                          </a>

                        </p>

                      </div>
                  ))
                }
              </div>
          }


        </section>
      </>

  )

};

Dealers.propTypes = {
  emailPartner: PropTypes.string,
  visitPartner: PropTypes.string
};

export default Dealers;
